import React, { Component } from "react"

export default class ContactForm extends Component {
  render() {
    const action = process.env.AZURE_FN_ENDPOINT
    return (
      <form
        className="nobottommargin contact-form"
        id="template-contactform"
        name="template-contactform"
        action={action}
        method="post"
      >
        <div className="form-process" />
        <div className="frm-control">
          <label htmlFor="template-contactform-name">
            First &amp; Last Name<small>*</small>
          </label>
          <input
            type="text"
            id="template-contactform-name"
            name="template-contactform-name"
            className="sm-form-control required"
          />
        </div>

        <div className="clear" />

        <div className="frm-control">
          <label htmlFor="template-contactform-company">Company</label>
          <input
            type="text"
            id="template-contactform-company"
            name="template-contactform-company"
            className="sm-form-control"
          />
        </div>

        <div className="clear" />

        <div className="frm-control">
          <label htmlFor="template-contactform-email">
            Email Address<small>*</small>
          </label>
          <input
            type="email"
            id="template-contactform-email"
            name="template-contactform-email"
            className="required email sm-form-control"
          />
        </div>

        <div className="clear" />

        <div className="col_full">
          <label htmlFor="template-contactform-message">
            Tell us about your brand’s business objectives and any other
            relevant information
            <small>*</small>
          </label>
          <textarea
            className="required sm-form-control"
            id="template-contactform-message"
            name="template-contactform-message"
            rows="6"
            cols="30"
          />
        </div>

        <div className="col_full hidden">
          <input
            type="text"
            id="template-contactform-botcheck"
            name="template-contactform-botcheck"
            className="sm-form-control"
          />
        </div>

        <div className="col_full">
          <button
            className="button  button-dark button-rounded button-large noleftmargin"
            type="submit"
            id="template-contactform-submit"
            name="template-contactform-submit"
            value="submit"
          >
            Submit
          </button>
        </div>
      </form>
    )
  }
}
