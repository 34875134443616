import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"

import ScrollArrow from "../components/scroll-arrow"
import Containers from "../components/containers"
import ContactForm from "../components/contact-form"

const ContactPage = props => {
  const page = props.data.page

  return (
    <Layout>
      <SEO title={page.title} />
      <Helmet
        bodyAttributes={{
          class: "stretched page-contact-us",
        }}
      />
      <Containers data={[page.containers[0]]} />
      <ScrollArrow />
      <section id="content">
        <div className="content-wrap">
          <div className="container clearfix">
            <div data-role="contact-intro">
              <Containers data={page.containers.slice(1)} />
            </div>
            <div
              className="contact-widget"
              id="contact-form"
              data-alert-type="inline"
            >
              <ContactForm />
            </div>
          </div>
          <div className="contact-form-result-container">
            <div
              className="contact-form-result contact-title center"
              style={{ display: "none" }}
            >
              Thank you for contacting us. We will get back to you within 1
              business day. Looking forward to helping you reach your goals!
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ContactQuery($slug: String!) {
    page: graphCmsPage(slug: { eq: $slug }) {
      ...GraphCmsPage
    }
  }
`

export default ContactPage
